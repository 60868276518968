import { TabItems } from "../common/components/Tabs";

export const tabItems: TabItems[] = [
    {
      title: "About",
      uId: "about",
    },
    {
      title: "Resume",
      uId: "resume",
    },
    {
      title: "Contact",
      uId: "contact",
    },
  ];

